.login-page{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    display: flex;
    font-family: 'MyOutfit-L', sans-serif;
    background-image: var(--background-img);
    background-repeat: repeat;
    background-size: 50%;
    color: white;
}

.login-page h1 {
    font-family: 'MyOutfit-L', sans-serif;
    font-size: 2.5rem;
    font-weight: 500;
}

.login-page form {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-submit {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.login-submit input{
    height: 42px;
    width: 42px;
    margin: 10px;
}

.login-submit input:hover {
    cursor: pointer;
    transform: translateY(2px);
    transition: all 0.2s ease-in-out;
}

.login-inputs {
    background-color: #fff;
    border-radius: 32px;
    padding: 0.2em 0px;
    width: 250px;
}

.login-inputs input{
    width: 100%;
    display: flex;
    height: 50px;
    justify-content: center;
    font-size: medium;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 10px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.username{
    margin-bottom: 0px;
    border-radius: 32px 32px 0px 0px;
    border: none;
    border-bottom: 1px solid #000;
}

.password{
    margin-top: 0px;
    border: none;
    border-radius: 0px 0px 32px 32px;
}

.username:focus, .password:focus{
    outline: none;
}

.login-signup-link {
    font-size: small;
    text-decoration: none;
}

.login-signup-link button {
    background-color: transparent;
    border: none;
    font-size: small;
    color:rgb(155, 101, 255);
    text-decoration: none;
    cursor: pointer;
}

.login-signup-link button:hover {
    text-decoration: underline;
}
