.todays-syllabyte {
    display: inline-block;
    min-height: fit-content;
    align-content: center;
    margin-top: 3%;
    color: var(--text-color);
    font-weight: 600;
    border: 1px dashed var(--text-color);
    border-radius: 3px;
    padding: 8px;
}

.todays-syllabyte:hover {
    cursor: pointer;
    background-color: #67CB65;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

@media screen and (max-width: 600px) {
    .todays-syllabyte .button {
        font-size: 8vw;
    }
}
