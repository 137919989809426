.landing-page {
    background-color: #292929;
    overflow: scroll;
    font-family: 'MyOutfit-L', sans-serif;
    color: white;
}

.landing-page {
    background-color: #292929;
    overflow: scroll;
    font-family: 'MyOutfit-L', sans-serif;
    color: white;
}

.Footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .Footer p {
    margin: 0;
  }
  
  .Footer a {
    color: #2daf3f;
    text-decoration: none;
  }
