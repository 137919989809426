.landing-page > .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px white solid;
    height: 80px;
    position: fixed;
    z-index: 1;
    background-color: inherit;
}

.landing-page > .header > .logo > img {
    height: 70px;
    width: 70px;
}

.landing-page > .header > .logo {
    margin: 10px;
}

.landing-page > .header > .header-text {
    font-family: 'MyOutfit-L', sans-serif;
    color: white;
    margin: 10px;
    margin-left: 0;
}

.landing-page > .header > .header-buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 10px;
    justify-content: space-between;
}

.header-buttons > button {
    background-color: #ececec;
    border-radius: 15px;
    color: black;
    font-weight: 600;
    font-family: 'MyOutfit-L', sans-serif;
    margin: 10px;
    padding: 5px;
    border: 1px solid black;
    transition: background-color 0.3s;
    width: 150px;
    height: 60px;
}

.header-buttons > button:hover {
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}.landing-page > .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px white solid;
    height: 60px;
    position: fixed;
    z-index: 1;
    background-color: inherit;
}

.landing-page > .header > .logo > img {
    height: 40px;
    width: 40px;
}

.landing-page > .header > .logo {
    margin: 10px;
}

.landing-page > .header > .header-text {
    font-family: 'MyOutfit-L', sans-serif;
    color: white;
    margin: 10px;
    margin-left: 0;
}

.landing-page > .header > .header-buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 10px;
    justify-content: space-between;
}

.header-buttons > button {
    background-color: #ececec;
    border-radius: 15px;
    color: black;
    font-weight: 600;
    font-family: 'MyOutfit-L', sans-serif;
    margin: 10px;
    padding: 5px;
    border: 1px solid black;
    transition: background-color 0.3s;
    width: 80px;
    height: 40px;
    min-width: max-content;
}

.header-buttons > button:hover {
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .header {
        font-size: 1.2em;
    }
}