.public-course-page {
    display: flex;
    flex-direction: row;
    font-family: "MyOutfit-L", sans-serif;
    font-weight: 900;
}

.public-course-page-sidebar {
    width: 20%;
    background-image: url("EmptySliderCoursePage.png");
    background-size: cover;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.public-course-page-sidebar-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 15px;
    margin: 20px;
    box-shadow: 8px 8px 0 rgb(62, 62, 62);
}

.public-course-page-sidebar-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.public-course-page-sidebar-logo:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease-in-out;
}

.public-course-page-sidebar-logo img {
    width: 150px;
}

.public-course-page-sidebar-text button {
    background-color: #2fa7fc;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.public-course-page-sidebar-text button:hover {
    background-color: #773fdf;
    transition: background-color 0.3s ease-in-out;
}

.public-course-page-sidebar h2 {
    font-size: 2em;
    margin: 0 auto;
}

.public-course-page-main {
    background-image: var(--background-img);
    background-size: cover;
    background-repeat: repeat;
    min-width: 80%;
    color:white; 
    padding: 10px 50px;
    border-left: 10px solid #585858;
    overflow-y: scroll
} 

.public-course-page-main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.public-course-page h1 {
    font-size: 5em;
    margin: 0 auto;
}

.public-course-page h2 {
    font-size: 1.8em;
    margin: 0 auto;
}

.public-course-page-main-heatmap {
    background-color: #ffffff;
    border-radius: 10px;
    margin: 50px auto;
    border: 5px solid #000000;
}

@media (max-width: 1024px) {
    .public-course-page-main {
        padding: 10px;
    }
    .public-course-page-main-heatmap {
        display: none;
    }
}

@media (max-width: 768px) {
    .public-course-page {
      flex-direction: column;
    }
  
    .public-course-page-sidebar {
        order: 2;
        width: 100%;
        height: 20%;
        background-image: url("EmptySliderCoursePage-horizontal.png");
    }
    
    .public-course-page-sidebar-logo img {
        display: none;
     }
     
    .public-course-page-main {
      min-width: auto;
      width: 100%;
      border-left: none;
      border-bottom: 10px solid #585858;
      padding: 10px;
      height: 80%;
    }
  
    .public-course-page-main-header {
      flex-direction: column;
      align-items: center;
    }

    .public-course-page-sidebar-text {
        height: 70%;
        font-size: 0.8em;
    }
  
    .public-course-page h1 {
      font-size: 3em;
      text-align: center;
    }
  
    .public-course-page h2 {
      font-size: 1.5em;
      text-align: center;
    }
  }