.course {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2% 0;
}

.course button {
    background-color: #FFFFFF;
    border-radius: 6px;
    display: inline-flex;
    width: 80%;
    height: 3rem;
    font: 300;
    align-items: center;
    border: 1px dashed #000;
    flex-direction: row;
}

.course button:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.course button:active {
    border: 1px solid #000;
}

.course-button-none {
    border: #000000 1.5px dashed;
    height: 40vh;
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background: #4e4e4e;
    border-radius: 6px
}
.course-priority {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
}

.course-name {
    margin-left: 1rem;
    font-weight: 500;
}

/* Hide the course name on overflpw */
.course-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loader-tasklist {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2% 0;
}