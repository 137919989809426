.demo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(-45deg, #050505, #4f4f4f);
	background-size: 200% 200%;
	animation: gradient 10s ease infinite;
    padding: 10%;
    font-family: 'MyOutfit-L', sans-serif;
}

.demo-header {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    align-items: center;
}

.demo-header img {
    border-radius: 20px;
    height: 100px;
    width: 100px;
    margin: 20px;
}

.demo-container h1 {
    color: white;
    font-size: 120px;
    font-weight: bold;
}

.demo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.demo-image img { 
    height: 500px;
}

.demo-content button {
    background-color: #000000;
    color: #ffffff;
    border: dashed 2px #ffffff;
    border-radius: inherit;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 30px;
    font-weight: bold;
    transition: all 0.2s ease;
}

.demo-content button:hover {
    background-color: #252525;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
