.about-task {
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 180px;
    width: 100%;
    font-size: 0.8rem;
    border: 2px dashed #8a8a8a;
    border-radius: 6px;
    background: #1e1e1e92;
    padding: 2px;
    text-align: left;
    padding: 10px;
}

.close-task {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 5%;
}

.close-task > button {
    cursor: pointer;
    background: none;
    color: white;
    border: none;
    border-radius: 10px;
    transition: 0.1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 50%;
}

.close-task > button:hover {
    background-color: #b2b2b2;
}

.about-task-title {
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-bottom: 2px solid #77b2ff;
}

.about-task-title > p {
    font-size: 1em;
    font-weight: bold;
    padding-bottom: 5px;
    margin: 0;
    color: #77b2ff;
}

.about-task-detail {
    font-size: 1.1em;
    display: flex;
    justify-content: space-between;
}

.about-task-detail > p {
    margin: 5px 0;
}

.about-task-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: #1a73e8;
    text-decoration: none;
    transition: color 0.3s ease;
    padding: 0;
}

.about-task-buttons > button > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    color: white;
}

.about-task-buttons > button > img {
    width: 20px;
    height: 20px;
}

.about-task-buttons > button {
    background: none;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    padding: 0;
    height: 40px;
    width: 100%;
}

.about-task-buttons > button:hover {
    background-color: #a9a9a9;
    cursor: pointer;
}

.no-task-selected {
    text-align: center;
    padding: 20px;
    color: #e2e2e2;
    font-weight: bold;
    font-size: 1.2em;
}
