@import "react-big-calendar/lib/sass/styles";

// Custom styling variables
$calendar-bg: #f3f3f3; // Dark background for a modern feel
$calendar-text-color: #000000; // Light text color for contrast
$event-bg: white;
$event-text-color: rgb(29, 29, 29);
$event-border-color: transparent;
$header-bg: #2faf2de0;
$hover-color: rgb(242, 234, 76);
$border-radius: 4px;
$box-shadow: 8px 8px 0px rgb(0, 0, 0);

.calendar {
    border: 2px solid #000;
    box-shadow: $box-shadow;
    margin: 1em 0;
    border-radius: 6px;
    overflow: hidden;
}

.rbc-calendar {
    background-color: $calendar-bg;
    color: $calendar-text-color;
    font-family: "MyOutfit-L", sans-serif;
    font-size: 0.9rem;
}

.rbc-today {
    background-color: #f9d64b;
}

.rbc-toolbar {
    background-color: $header-bg;
    color: white;
    padding: 10px;
    border-bottom: 1px solid #444;
    font-size: 1rem;
    border-radius: $border-radius $border-radius 0 0;
}

.rbc-toolbar button {
    background: none;
    border: none;
    color: $calendar-text-color;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 0.85rem;
    transition: background 0.3s ease;
    &:hover {
        background-color: $hover-color;
        border-radius: $border-radius;
    }
}

.rbc-event {
    background: $event-bg;
    color: $event-text-color;
    padding: 2px 10px;
    border-radius: $border-radius;
    border: 1px dashed #000;
    margin: 1px 0;
    box-shadow: 3px 3px 0 0 #4f4f4fbc;
    font-size: 0.85rem;
}

.rbc-event.rbc-selected {
    background-color: #f9d64b;
}

.rbc-off-range-bg {
    background-color: #cecece;
}

.rbc-show-more {
    background-color: transparent;
    color: $calendar-text-color;
    padding: 4px 8px;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: 0.7rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
    &:hover {
        background-color: $hover-color;
        transform: scale(1.05);
    }
}