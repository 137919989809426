.add-course-button {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5% 10%;
    background-color: #FFFFFF;
    border-radius: 6px;
    display: inline-flex;
    height: 2rem;
    align-items: center;
    padding: 1%;
    border: 1px dashed #000;
    width: 76.5%;
    box-sizing: unset;
}

.add-course-button:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.button-left {
    width: 80%;
    float: left;
    text-align: left;
    font-family: 'MyOutfit-L', sans-serif;
}

.button-right {
    height: 100%;
    width: auto;
    align-items: center;
    float: right;
}

.button-right img{
    height: 100%;
}
.uniname {
    color: var(--heading-color);
    margin-top: 0;
    margin-bottom: 1rem;
}


@media only screen and (max-width: 300px) {
    .button-left {
        display: none;
    }

    .button-right {
        width: 100%;
        float: center;
    }

    .add-course-button {
        width: fit-content;
    }
}
