.course-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.course-banner {
    width: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23000000' fill-opacity='0.1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #f4f4f4;
    color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.619);
    border-radius: 6px;
    padding: 2%;
    margin: 2%;
    transition: 0.2s ease all;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.course-banner:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.course-banner-content > h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.course-banner-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
}

.course-banner-footer > p {
    font-size: 0.8em;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.course-settings > form {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    height: 100%;
    width: 50%;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: row;
    margin: 5px;
    justify-content: space-between;
}

.form-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;
}

.form-group > label {
    width: 30%;
    font-weight: 600;
    margin-bottom: 0;
}

.form-group > input {
    width: 70%;
    border-radius: 6px;
    border: 0;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    color: #333;
}

.course-form-container {
    background-color: var(--course-form-container-background);
    border-radius: 6px;
    padding: 2%;
    margin: 2%;
    border: 2px solid #000;
    width: 100%;
}

.course-form-button {
    background-color: #bbbbbb4c;
    width: fit-content;
    border-radius: 8px;
    margin: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.course-form-button > button {
    transform: translate(-4px, -6px);
    transition: 0.1s ease all;
}

.course-form-button > button:hover {
    transform: translate(3px, 3px);
}