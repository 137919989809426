.main-list {
    display: flex;
    flex-direction: row;
}

.list-frame {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-frame {
    padding-top: 5em;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.list-header {
    margin: 0px;
    padding-bottom: 0%;
    border-bottom: 4px solid #1a1a1a;
}

.list-wrapper {
    padding-left: 5%;
    max-height: 85vh;
    display: flex;
    margin-top: 0;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-tasks {
    margin: 0;
    padding: 0;
}

.list-box {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    background-color: #fff;
    border-radius: 6px;
    background: var(--todo-box-color);
    border: 4px solid #1a1a1a;
    height: 85vh;
    padding-top: 0%;
    overflow-y: scroll;
    overflow-x: scroll;
    scrollbar-width: none;
    width: 28rem;
    box-shadow: 0.3em 0.3em 0 0 rgba(0, 0, 0, 0.25);
}

.list-box::-webkit-scrollbar {
    width: 0;
}

.date {
    color: var(--date-color);
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 2%;
}

.todo-table {
    table-layout: fixed;
    border-collapse: collapse;
    width: inherit;
}

.todo-table th {
    color: var(--text-color);
    font-family: "MyOutfit-L", sans-serif;
    font-weight: 200;
    border-bottom: 3px solid #1a1a1a;
    font-size: 1.2rem;
}

.todo-table td {
    color: black;
    align-items: center;
    border-bottom: 2px solid #1a1a1a;
    font-weight: 500;
    font-size: 1rem;
    font-family: "MyOutfit-L", sans-serif;
}

.container {
    display: block;
    position: relative;
    margin-bottom: 25px;
    margin-left: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.todo-table td input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.filter-box {
    border: 2px dashed var(--text-color);
    border-radius: 6px;
    width: 100%;
    margin: 1rem;
    padding: 0.5em;
    font-weight: bold;
    font-size: medium;
    height: fit-content;
}

.filter-box-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.25em;
}

.filter-box-square {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--text-color);
    border-radius: 6px;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.todo-table input:checked ~ .checkmark {
    background-color: #33a848;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.todo-table input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.todo-table .checkmark:after {
    left: 9px;
    top: 4px;
    width: 8px;
    height: 13.5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.todo-table td input:hover {
    cursor: pointer;
}

.no-tasks {
    color: var(--text-color);
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    font-size: x-large;
}

.feature-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    margin-top: 1em;
    padding: 1em;
}

.feature-button-wrapper {
    height: 80px;
    width: 80px;
    border-radius: 6px;
}

.feature-buttons button {
    height: 100%;
    color: #000;
    border: var(--border-boxes);
    border-radius: 6px;
    font-size: small;
    cursor: pointer;
    transition: all 0.1s ease 0s;
    transform: translate(-3px, -3px);
}

.feature-buttons button:hover {
    transform: translate(1px, 1px);
    cursor: pointer;
}

#col-syllabyte-list-desktop {
    display: table;
}

#col-syllabyte-list-phone {
    display: none;
}

.task-list-footer {
    display: flex;
    justify-content: end;
}

.add-new-task-wrapper {
    height: 40px;
    width: 40px;
    margin: 0.5em;
    background-color: #676767;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease 0s;
}

.add-new-task-button {
    height: 100%;
    transition: all 0.1s ease 0s;
    transform: translate(-3px, -3px);
    background-color: inherit;
    border-radius: 12px;
    border: none;
    padding: 0;
    border: 2px solid #000000;
}

.add-new-task-button:hover {
    transform: translate(1px, 1px);
    cursor: pointer;
    background-color: #60cb56;
    border-radius: 12px;
}

.add-new-task-button img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* remove date field on smaller screen sizes */
@media only screen and (max-width: 600px) {
    #col-syllabyte-list-phone {
        display: table;
    }

    #col-date,
    #syllabyte-list-header-desktop,
    #col-syllabyte-list-desktop,
    .side-frame {
        display: none;
    }

    .list-wrapper {
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .list-box {
        width: 70vw;
        height: 90vw;
    }

    .date {
        font-size: 1.5rem;
    }

    .todo-table td {
        font-size: 0.9rem;
    }

    .list-frame {
        width: 100%;
    }
}

@media screen and (max-width: 1140px) {
    .filter-box {
        display: none;
    }

    .side-frame {
        display: none;
    }
}
