.settings-button {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5em 0 0.5em 0;
    padding: 2%;
    text-decoration: none;
    color: #000;
    height: 2rem;
    display: inline-flex;
    background-color: #fff;
    width: 50%;
    align-items: center;    
    border: 1px dashed #000;
    box-sizing: unset;
}

.settings-button:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.button-left {
    width: 80%;
    float: left;
    text-align: left;
}

.button-right {
    height: 100%;
    width: auto;
    float: right;
}

@media only screen and (max-width: 870px) {
    .button-left {
        display: none;
    }

    .button-right {
        display: flex;
        justify-content: center;
    }

    .settings-button {
        width: fit-content;
    }
}

.preferences {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 5% 0;
}

.prefs {
    background: #fff;
    color: #000;
    border: var(--border-boxes);
    border-radius: 6px;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:40%;
}

.prefs-title {
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #000;
}

.prefs-explanation {
    width: 60%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.prefs-explanation-title {
    font-size: 1rem;
    font-weight: 600;
}
/* .prefs-explanation-exam {
    height: 3.5rem;
    width: 100%;
    background-color: #BBABFB;
    border-radius: 6px;
    margin: 2% 0;
    font-size: small;
} */
.prefs-explanation-assignment {
    width: 70%;
    background-color: #ffdb7e;
    border-radius: 6px;
    font-size: small;
    color: #000;
    margin: 2% 0;
    padding: 5% 1%;
    display: flex;
    justify-content: center;
}
.exam-conf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1% 0;
    width: 100%;
    height: 3rem;
}

.assignment-conf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5% 0;
    width: 100%;
    height: 3rem;
}
.personal-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
}

.form-box {
    background-color: #D9D9D9;
    border: var(--border-boxes);
    border-radius: 6px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
}

.personal-info-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1% 0;
    font-size: 0.6em;
}

.personal-info-field label {
    color: #000;
    justify-content: middle;
}

.personal-info-field input, .personal-info-field select {
    width: 50%;
    border-radius: 6px;
    padding: 2%;
    border: none;
    color: #838383;
    background-color: #fff;
    transition: 0.2s;
}
.personal-info-field input:focus, .personal-info-field select:focus {
    outline: none;
    color:#fff;
    background-color: #000000;
    transition: 0.3s;
}

.personal-info-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.submit-button {
    transition: 0.1s ease all;
    height: 50px;
    width: 100px;
    background-color: #ffdb7e;
    border-radius: 6px;
}

.submit-button button {
    background-color: #fff;
    border-radius: 6px;
    padding: 2%;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease 0s;
    border: none;
    font-size: large;
    transform: translate(-7px, -7px);
}

.submit-button button:hover {
    font-style: italic;
    cursor: pointer;
    transform: translate(2px, 2px);
}

.themes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0;
    background: #dbbdff;
    border-radius: 6px;
    color: black;
    padding: 0px 25px;
    border: var(--border-boxes);
}

.theme-choices {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
    height: 80px;
}

.theme-choice {
    border: 2px rgb(187, 171, 251) solid;
    border-radius: 6px;
    width: 30%;
    height: 80%;
}
.theme-choice[id="light"] {
    background: url('background-white.png');
    background-size: 85%;
}

.theme-choice[id="dark"] {
    background: url('background.png');
    background-size: 85%;
    color: #fff;
}

.theme-choice:hover {
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
}

.theme-choice[id="light"]:hover {
    background-color: #f3f3f3;
    color:#000;
}

.theme-choice[id="dark"]:hover {
    background-color: #333;
}
