.course-page-wrapper {
    height: -webkit-fill-available;
    width: 100%;
}

.course-page-wrapper input, button, textarea, select {
    font-size: initial;
}

.course-tasks-header {
    padding: 0;
    font-size: large;
    font-weight: 600;
    color: #000;
    background-color: #E0DBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #000;
    margin: 0;
    padding: 3px 0;
}

.course-wrapper {
    display: flex;
    flex-direction: row;
    height: 80vh;
    margin-top: 2%;
}

.course-wrapper-color {
    background-color: #E0DBFF;
    border-radius: 6px;
    height: inherit;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.course-tasks {
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
    padding: 1%;
    overflow: scroll;
    border: var(--border-boxes);
    /* hide scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.course-tasks::-webkit-scrollbar {
  display: none;
}

.course-tasks-table{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #E0DBFF;
    overflow-y: scroll;
    /* hide scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.course-tasks-table::-webkit-scrollbar {
  display: none;
}

.course-right-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 5%;
}

.course-tasks-table button {
    width: 100%;    
    height: 2.5em;
    background-color: #ECEBFF;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #5f5f5f;
    font-weight: 600;
    font-size: 0.45em;
}

/* change huvor */
.course-tasks-table button:hover {
    background-color: #d9bcff;
    cursor: pointer;
    transition: 0.1s;
}

.course-tasks-table button:active {
    background-color: #9A92FF;
    color: #fff;
}

.task-info {
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 500px;
    border-radius: 6px;
    padding: 2%;
    border: var(--border-boxes);
}

.task-info-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #C1E5FF;
    color: black;
}

.task-info-input {
    display: flex;
    font-size: 0.75em;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;
}

.task-info-input input {
    border-radius: 6px;
    width: 40%;
    border: none;
    font-weight: 600;
}

.task-info-input label {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* add transition animation when task info changes */
.task-info-flex {
    transition: all 0.5s ease;
}

.task-info-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;
}

.task-info-button {
    color: #fff;
    width: 50%;
    border: none;
    border-radius: 6px;
    margin: 0px 5px;
    transition: 0.1s ease all;
}

.task-info-button:hover {
    cursor: pointer;
}

.add-task-button {
    display: flex;
    justify-content: center;
}

.add-task-button button {
    margin: 2% 0;
    border-radius: 4px;
    border: none;
    background: white;
    padding: 15px;
    font-size: medium;
    font-family: inherit;
}

.add-task-button button:hover {
    cursor: pointer;
    color: #9A92FF;
}

.reset-to-algo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.reset-to-algo {
    transition: 0.1s ease all;
    height: 50px;
    width: 100px;
    background-color: #ffdb7e;
    margin: 5px;
    border-radius: 6px;
}

.reset-to-algo button {
    background-color: #fff;
    border-radius: 6px;
    padding: 2%;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease 0s;
    border: none;
    font-size: medium;
    transform: translate(-7px, -7px);
}

.reset-to-algo button:hover {
    cursor: pointer;
    transform: translate(1px, 1px);
}

.tab {
    position: relative;
    border-radius: 10px;
    border: 1px dashed black;
    background-color: white;
    color: black;
    font-size: 16px;
    margin: 10px;
    transition: 0.1s ease-in-out all;
    opacity: 0.5;

    &[data-active] {
        z-index: 1;
        background-color: var(--mantine-color-white);
        color: var(--mantine-color-black);
        box-shadow: var(--mantine-shadow-md);
        opacity: 1;
    }

    &:hover {
        background-color: var(--mantine-color-white);
        color: var(--mantine-color-black);
        box-shadow: var(--mantine-shadow-md);
        opacity: 1;
    }
}
