#heatmap {
    border-radius: 6px;
    background-color: var(--background-boxes);
    padding: 1%;
    margin-bottom: 5%;
    box-shadow: 6px 6px #3c80d9;
}
.apexcharts-tooltip span {
    color: var(--sidebar-background);
}
.workload-charts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1%;
}
.pie-charts{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1%;
}
.days-left {
    display: flex;
    flex-direction: column;
}
.days-left h1 {
    color: var(--text-color);
    font-size: 10rem;
    margin: auto;
}