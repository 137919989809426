.slider {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.slider > div {
    width: 100%;
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 2%;
    text-align: left;
    border-radius: 6px;
}
