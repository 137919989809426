.course-preview-subtitles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mantine-Modal-header {
    position: static;
}

.course-preview-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    border: 2px dashed #171717;
    font-family: "MyOutfit-L", sans-serif;
    font-size: 1rem;
}

.course-preview-table th,
.course-preview-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.course-preview-table th {
    background-color: #c2c2c2;
    text-align: left;
    color: #333;
    font-weight: bold;
}

.course-preview-table tr {
    background-color: #ffffff;
    color: #646464;
}
.course-preview-table tr:nth-child(even) {
    background-color: #f9f9f9;
}
    
.course-preview-table tr:hover {
    background-color: #f1f1f1;
}

.course-preview-about-pills {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .course-preview-table {
        font-size: 0.8em;
    }
}
