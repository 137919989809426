.profile {
    margin: 5% 0;
}
.profile-pic {
    /* border-radius: 12px;
    border: 2px solid #000; */
    width: 150px;
    height: 150px;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    margin: 5% 0;
}

.profile-pic img {
    /* border-radius: 50%; */
    height: 100%;
    width: 100%;
}

.profile-name {
    color: var(--text-color);
    font-size: 1.5em;
    font-weight: 600;
}

@media screen and (max-width: 870px) {
    .profile-pic {
        height: 80%;
        width: 80%;
    }
}
