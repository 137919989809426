.verification-wrapper {
    display:block;
    background-color: rgb(255, 210, 62);
    border-radius: 10px;
    padding: 1rem;
    width: 50%;
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    transform: translate(50%, 50%);
}