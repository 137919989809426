:root,
  :root.dark {
  --background-img: url("background.png");
  --text-color: #fff;
  --sidebar-background: #3A3A3A;
  --sidebar-border: #fff;
  --text-color: #fff;
  --border-boxes: none;
  --background-boxes: #fff;
  --todo-box-color: #989898;
  --date-color: #67CB65;
  --heading-color: #97B4FF;
  --inverse-text-color: #000;
  --course-form-container-background: #5c5c5c;
  }

  :root.light {
  --background-img: url("LightMode.png");
  --text-color: #000;
  --sidebar-background: #BEBEBE;
  --sidebar-border: #000;
  --text-color: #000;
  --border-boxes: 2px solid #8a8a8a;
  --background-boxes: #ededed;
  --todo-box-color: #BEBEBE;
  --date-color: #812BEE;
  --heading-color: #0048ff;
  --inverse-text-color: #fff;
  --course-form-container-background: #dadada;
  }

body > #root > div {
  height: 100vh;
}

.App {
  text-align: center;
  display: flex;
  font-family: 'MyOutfit-L', sans-serif;
}

.App-link {
  color: #61dafb;
}

.side-bar {
  width: 30%;
  height: 100%;
  background-color: var(--sidebar-background);
  border-right: 3px solid var(--sidebar-border);
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.side-bar::-webkit-scrollbar {
  display: none;
}
.header {
  align-items: center;
  width: 100%;
  font-weight: 600;
  font-size: 2vw;
  line-height: 1rem;
}

.list-pane {
  position: "relative";
  align-items: center;
  width: 100%;
  /* background-color: #000000; */
  background-image: var(--background-img);
  background-repeat: repeat;
  background-size: 50%;
  padding-left: 4%;
  padding-right: 4%;
  font-family: 'MyOutfit-L', sans-serif;
  font-weight: 400;
  font-size: 1.75em;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  color: var(--text-color);
} 

.list-pane::-webkit-scrollbar {
  display: none;
}

.loader-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  /* background-image: url("background.png");
  background-repeat: repeat; */
}

.loader {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #ffffff transparent #000000 transparent ;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.list-pane body {
  padding: 3%;
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 1rem;
}

.issue p {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--text-color);
  text-decoration: none;
}

.issue a {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--text-color);
  text-decoration-color: var(--text-color);
}

.issue a:hover {
  color: #ffb4b4;
  text-decoration-color: #ffb4b4;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.log-out {
  position: absolute;
  right: 2%;
  top: 0;
  transition: 0.3s;
}

.log-out a {
  font-size: 1.5vw;
  color: #b0b0b0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.log-out a:hover {
  color: #838383;
}

/* remove side bar on phones */
@media only screen and (max-width: 600px) {
  .side-bar {
    width: 0%;
    border: none;
  }

  .list-pane {
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .header {
    font-size: 3vw;
  }

  .list-pane body {
    font-size: 3vw;
  }

  .log-out a{
    font-size: medium;
  }

}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}