.sidebar-waitlist {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: var(--text-color);
    width: auto;
  }


.sidebar-button-general {
    background-color: #FFFFFF;
    border-radius: 6px;
    display: inline-flex;
    border: 2px solid #000;
    transition: all 0.2s ease-in-out;
}

.sidebar-button-general:hover {
    background-color: #ffc953;
    cursor: pointer;
}