  .hero {
    position: relative;
    overflow: hidden;
    margin-top: 80px;
  }

  .image-container {
    top: 0;
    left: 0;
    display: flex;
    width: 400%; /* Double the width for two images */
    overflow: hidden;
  }
  
  .image-container img {
    width: 50%;
    height: auto;
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }  .hero {
    position: relative;
    overflow: hidden;
    margin-top: 60px;
  }

  .image-container {
    top: 0;
    left: 0;
    display: flex;
    width: 400%; /* Double the width for two images */
    overflow: hidden;
  }
  
  .image-container img {
    width: 50%;
    height: auto;
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

@media screen and (max-width: 768px) {
  .image-container {
    width: 800%;
  }
  
}