.why {
    margin: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.why img {
    width: 60%;
    height: 60%;
}

.why-item {
    padding: 2% 8%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.simple-flow-title {
    font-size: 1.5rem;
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    width: 30%;
}

.simple-flow-img {
    width: 70%;
}

.why-item img {
    width: 100%;
    height: auto;
}

#title-2 {
    text-align: end;
}

#title-1 {
    text-align: start;
}.why {
    margin: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.why img {
    width: 60%;
    height: 60%;
}

.why-item {
    padding: 2% 8%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.simple-flow-title {
    font-size: 1.5rem;
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    width: 30%;
}

.simple-flow-img {
    width: 70%;
}

.why-item img {
    width: 100%;
    height: auto;
}

.title-2 {
    text-align: end;
}

.title-1 {
    text-align: start;
}

#try-today-btn {
    width: 200px;
    height: 70px;
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    .why {
        margin: 2% 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .why img {
        width: 90%;
        height: 60%;
    }

    .why-item {
        padding: 8% 8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .simple-flow-title {
        font-size: 1rem;
        width: 100%;
    }

    .simple-flow-img {
        width: 70%;
    }

    .why-item img {
        width: 100%;
        height: auto;
    }

    .title-2 {
        text-align: end;
    }

    .title-1 {
        text-align: start;
    }

    #try-today-btn {
        width: 200px;
        height: 70px;
        font-family: "Outfit", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 1.5rem;
    }   
}