.register-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.register-page-logo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* add a gradient moving backgroun */
    background: linear-gradient(45deg, rgb(70, 70, 70), rgb(27, 27, 27));
    background-size: 200% 200%;
    /* make it sparkle every 15 s */
    animation: gradient 15s ease infinite;
    border-right: 10px solid rgb(0, 0, 0);
}

.register-page-logo img {
    width: 40%;
}

.register-page-signup {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    background-color: #f8f9fa;
}

.form-container {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.register-inputs {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.register-inputs label {
    font-weight: 600;
    color: #333;
    margin-bottom: 0.25rem;
}

.register-inputs input,
.register-inputs select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.register-inputs input:focus,
.register-inputs select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.password-error {
    display: none;
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.register-submit {
    margin-top: 2rem;
    text-align: center;
    background-color: #007bff;
    border-radius: 4px;
    border: none;
    width: fit-content;
}

.register-submit button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #5cabff;
    border: inherit;
    border-radius: inherit;
    cursor: pointer;
    transform: translate(-3px, -3px);
    transition: all 0.1s ease-in-out;
}

.register-submit button:hover {
    background-color: #1179e9;
    transform: translate(1px, 1px);
}

.loading-spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #0d76ff;
    animation: spin 1s ease-in-out infinite;
}

.sublabel {
    font-size: 0.8rem;
    color: #6c757d;
    margin-top: -0.4rem;
    margin-bottom: 0.4rem;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 600px) {
    .form-container {
        margin: 1rem;
        padding: 1.5rem;
    }
}

@media (max-width: 600px) {
    .register-page {
        flex-direction: column;
        align-items: center;
    }
    .register-page-logo {
        width: 100%;
        border-right: none;
        height: 10%;
    }
    .register-page-logo img {
        width: 10%;
    }
    .register-page-signup {
        width: 100%;
    }
}
