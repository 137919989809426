.search-container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 0;
}

.search-bar {
    background-color: #FFFFFF;
    padding: 1%;
    border-radius: 6px;
    border: var(--border-boxes);
}
.search-bar input {
    background-color: #D9D9D9;
    width: 100%;
    border-radius: 6px;
    height: 100%;
    padding-left: 1%;
    border-color: transparent;
    font-size: smaller;
}

.search-results-container {
    margin: 2%;
    background-color: #fff;
    border: var(--border-boxes);
    border-radius: 6px;
    height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    border: 1.5px dashed black;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.search-results-container::-webkit-scrollbar {
  display: none;
}

.search-results {
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px;
}

.search-results-button {
    width: 90%;
    height: 4rem;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    background-color: #AADDDD;
    font-size: large;
    border: none;
}

.search-results-button:hover {
    background-color: #88CCDD;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.course-info {
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.course-extra-info {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.course-semester-info {
    color: #646464;
}

.course-enrollment-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
}

.lock-icon {
    margin-left: 0.5rem;
}

.user-icon {
    color: #808080;
}

.user-icon.enrolled {
    color: rgb(31, 147, 31);
}

.course-selection-button {
    height: 2rem;
    width: 2rem;
    background-color: transparent;
    padding: 0;
    border: none;
}

.course-selection-button:hover {
    cursor: pointer;
    box-shadow: #505050;
    transform: scale(1.1);
    transition: 0.2s;
}

/* animate the transition on click */
.search-results button:active {
    transform: scale(0.9);
    transition: 0.1s;
}

/* Make image smaller in size */
.search-results img {
    width: 100%;
}
