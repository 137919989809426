/* Shepherd.js Tour Styles */
.shepherd-element {
    max-width: 400px;
    border-radius: 8px;
    border: 2px dashed #5c5c5c;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    color: #333333;
    font-family: "MyOutfit-L", sans-serif;
    margin: 0px 10px;
}

.shepherd-text {
    padding: 20px;
    font-size: 16px;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
}

.shepherd-text img {
    align-self: center;
    margin-top: 10px;
}

.shepherd-header {
    padding: 15px 20px;
    background-color: #6b46c1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.shepherd-title {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
}

.shepherd-cancel-icon {
    color: #ffffff;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.shepherd-cancel-icon:hover {
    opacity: 1;
}

.shepherd-footer {
    padding: 10px 20px 20px;
}

.shepherd-button {
    margin-right: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s;
}

.shepherd-button-primary {
    background-color: #6b46c1;
    color: #ffffff;
}

.shepherd-button-primary:hover {
    background-color: #553c9a;
}

.shepherd-button-secondary {
    background-color: #e2e8f0;
    color: #4a5568;
}

.shepherd-button-secondary:hover {
    background-color: #cbd5e0;
}

.shepherd-arrow:before {
    background: #6b46c1;
}

.shepherd-arrow {
    display: none;
}

.tour-gif {
    width: 300px;
    height: auto;
    border-radius: 8px;
    border: 6px solid #000000;
}