.warning-box{
    background-color: #FFBD59;
    border-radius: 6px;
    border: 2px solid #FF0B0B;
    display:flex;
    flex-direction: row;
    align-items: center;
    font-size: medium;
    padding: 1%;
    margin: 0.5rem;
    /* make it appear gracefully */
    animation: fadein 1s;
}

.warning-box img {
    height: fit-content;
    width: 40px;
    margin-left: 10px;
}
